import index from ".";
import Card from "../../components/card";
import Footer from "../../components/footer";
import data from "./data";

function Home() {
  return (
    <div data-aos="fade" className="aos-init aos-animate">
      <div className="pt-12 md:py-12 px-2 sm:px-5 md:px-10 lg:px-14">
        <div className="flex items-center mb-6">
          <h1 className="text-4xl font-semibold after-effect after:left-52 dark:text-white">
            About Me
          </h1>
          <div className="h-1 w-24 bg-red-500  ml-5 mt-2"></div>
        </div>
        <div className="lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center ">
          <div className="col-span-12 space-y-2.5">
            <div className="lg:mr-16">
              {data.about.paragraph.map((paragraph, index) => (
                <p
                  className="text-gray-lite dark:text-[#A6A6A6] leading-7"
                  key={index}
                >
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="pb-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
        <h3 className="text-[35px] dark:text-white font-medium pb-5">
          What I do!
        </h3>
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 ">
          {data.professions.map((profession, index) => (
            <Card
              key={index}
              src={profession.src}
              title={profession.title}
              description={profession.description}
              bgColor={profession.bgColor}
            />
          ))}
        </div>
      </div>

      <div className="px-2 sm:px-5 md:px-10 lg:px-14 mb-10">
        <h3 className="text-[35px] dark:text-white font-medium pb-5">
          My Clients!
        </h3>
        <div className="bg-[#F8FBFB] dark:bg-[#0D0D0D] max-w-full h-auto py-6 rounded-xl px-12">
          <div className="w-[200px] mx-auto flex flex-wrap justify-between">
            {data.clients.map(({ link, src }, index) => (
              <a href={link}>
                <img src={src} key={index} alt={index} className="h-16" />
              </a>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
