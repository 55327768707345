const data = {
  about: {
    paragraph: [
      "I am a versatile individual who has thrived in various domains, including software engineering, graphic design, sales, and accounting. My journey reflects a commitment to learning and a passion for solving complex problems. My unique skill set, blending technical expertise with creative thinking, positions me as a valuable asset capable of driving success in multifaceted environments",
    ],
  },
  professions: [
    {
      src: "https://img.icons8.com/color/48/000000/code.png",
      bgColor: "bg-pink-100",
      description:
        "Develop and manage fully responsive, dynamic websites by harnessing modern web technologies for seamless user experiences.",
      title: "Web Development",
    },
    {
      src: "https://img.icons8.com/color/48/000000/design.png",
      bgColor: "bg-yellow-100",
      description:
        "Create compelling visuals for marketing and branding, including eye-catching promotional materials, and digital assets.",
      title: "Graphic Design",
    },
    {
      src: "https://img.icons8.com/color/48/000000/design.png",
      bgColor: "bg-blue-100",
      description:
        "Enhance user experiences with well-crafted interfaces and design solutions that meet user needs.",
      title: "UI/UX Design",
    },
    {
      src: "https://img.icons8.com/color/48/000000/task.png",
      bgColor: "bg-green-100",
      description:
        "Lead and coordinate teams while building strong client relationships to enhance productivity and achieve business goals.",
      title: "Management",
    },
  ],
  clients: [
    {
      src: "./images/slider/life-company.png",
      link: "https://www.lifephc.com/",
    },
    {
      src: "./images/slider/alhadbaa.png",
      link: "https://alhadbaa.com/",
    },
  ],
};
export default data;
