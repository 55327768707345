import "./App.css";
import Layout from "./pages/layout";
import { BrowserRouter } from "react-router-dom";

function App() {
  // document.getElementsByName("html").item().classList.contains("dark")
  return (
    <div className="bg-homeBg bg-homeTwoBg min-h-screen bg-no-repeat bg-center bg-cover bg-fixed md:pb-16 w-full">
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </div>
  );
}

export default App;
