import { NavLink } from "react-router-dom";

function Item({
  activeClass = "lg:text-white lg:dark:text-white lg:bg-gradient-to-r from-[#FA5252] to-[#DD2476]",
  className = "w-full h-20 rounded-[10px] cursor-pointer font-poppins bg-[#F3F6F6] font-medium mx-2.5 text-xtiny text-gray-lite dark:text-[#A6A6A6] justify-center flex flex-col items-center transition-all duration-200 ease-in-out dark:hover:text-white dark:bg-[#212425] hover:text-white hover:bg-gradient-to-r from-[#FA5252] to-[#DD2476]",
  icon = null,
  to = "",
  itemName = "",
}) {
  return (
    <NavLink
      className={({ isActive }) =>
        `${className} ${isActive ? activeClass : ""}`
      }
      to={to}
    >
      <span className="text-xl mb-1">{icon}</span>
      {itemName}
    </NavLink>
  );
}

export default Item;
