import { ReactComponent as Home } from "../../assets/svg/home.svg";
import { ReactComponent as Blog } from "../../assets/svg/blog.svg";
import { ReactComponent as Contact } from "../../assets/svg/contact.svg";
import { ReactComponent as Resume } from "../../assets/svg/resume.svg";
import { ReactComponent as Work } from "../../assets/svg/work.svg";
import Item from "./item";

function Nav() {
  return (
    <header className="lg:w-[526px] h-[144px] hidden lg:block p-[30px] ml-auto mb-10 rounded-[16px] bg-white dark:bg-[#111111] ">
      <nav className="hidden lg:block">
        <ul className="flex ">
          <Item icon={<Home />} to="/" itemName="Home" />
          <Item icon={<Resume />} to="/resume" itemName="resume" />
          <Item icon={<Blog />} to="/blog" itemName="Blogs" />
          <Item icon={<Work />} to="/work" itemName="Works" />
          <Item icon={<Contact />} to="/contact" itemName="Contact" />
        </ul>
      </nav>
    </header>
  );
}

export default Nav;
