import { ReactComponent as Home } from "../../assets/svg/home.svg";
import { ReactComponent as Blog } from "../../assets/svg/blog.svg";
import { ReactComponent as Contact } from "../../assets/svg/contact.svg";
import { ReactComponent as Resume } from "../../assets/svg/resume.svg";
import { ReactComponent as Work } from "../../assets/svg/work.svg";
import ItemNav from "./itemMobile";

function NavMobile({ state, className, openNavStateChanger }) {
  return (
    <nav
      className={`block ${
        !state ? "hidden" : ""
      } ${className} lg:hidden pt-24 z-10 block  rounded-b-[20px] shadow-md top-0 left-0  w-full bg-white dark:bg-[#212425] fixed`}
    >
      <ul className="flex-col">
        <ItemNav
          icon={<Home />}
          to="/"
          itemName="Home"
          openNav={openNavStateChanger}
        />
        <ItemNav
          icon={<Resume />}
          to="/resume"
          itemName="resume"
          openNav={openNavStateChanger}
        />
        <ItemNav
          icon={<Blog />}
          to="/blog"
          itemName="Blogs"
          openNav={openNavStateChanger}
        />
        <ItemNav
          icon={<Work />}
          to="/work"
          itemName="Works"
          openNav={openNavStateChanger}
        />
        <ItemNav
          icon={<Contact />}
          to="/contact"
          itemName="Contact"
          openNav={openNavStateChanger}
        />
      </ul>
    </nav>
  );
}

export default NavMobile;
