import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import Resume from "../pages/resume";
import Work from "../pages/work";
import Blog from "../pages/blog";
import Contact from "../pages/contact";
import Nav from "./nav";

function Content() {
  return (
    <section className="pt-20 lg:pt-0 col-span-12 lg:col-span-8">
      <Nav />
      <div className="lg:rounded-2xl bg-white dark:bg-[#111111]">
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/resume" exact element={<Resume />} />
          <Route path="/work" exact element={<Work />} />
          <Route path="/blog" exact element={<Blog />} />
          <Route path="/contact" exact element={<Contact />} />
        </Routes>
      </div>
    </section>
  );
}
export default Content;
