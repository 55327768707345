const data = {
  // video: [
  //   {
  //     cover: "https://picsum.photos/300/200?random=1",
  //     bgColor: "bg-pink-100",
  //     title: "Chul urina",
  //   },
  //   {
  //     cover: "https://picsum.photos/300/200?random=4",
  //     bgColor: "bg-blue-100",
  //     title: "Chul urrerina",
  //   },
  // ],
  // "web design": [
  //   {
  //     cover: "https://picsum.photos/300/200?random=2",
  //     bgColor: "bg-indigo-100",
  //     title: "Aura Dione",
  //   },
  //   {
  //     cover: "https://picsum.photos/300/200?random=2",
  //     bgColor: "bg-green-100",
  //     title: "Aura Dione",
  //   },
  //   {
  //     cover: "https://picsum.photos/300/200?random=2",
  //     bgColor: "bg-green-100",
  //     title: "Aura Dione",
  //   },
  // ],
  // logo: [
  //   {
  //     cover: "https://picsum.photos/300/200?random=3",
  //     bgColor: "bg-pink-100",
  //     title: "erwChul urawerina",
  //   },
  //   {
  //     cover: "https://picsum.photos/300/200?random=3",
  //     bgColor: "bg-yellow-100",
  //     title: "erul urersarina",
  //   },
  // ],
  // "Graphic design": [
  //   {
  //     cover: "https://picsum.photos/300/200?random=3",
  //     bgColor: "bg-yellow-100",
  //     title: "erul urersarina",
  //   },
  // ],
  // Application: [
  //   {
  //     cover: "https://picsum.photos/300/200?random=3",
  //     bgColor: "bg-pink-100",
  //     title: "erwChul urawerina",
  //   },
  // ],
};
export default data;
