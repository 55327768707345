import { NavLink } from "react-router-dom";

function ItemNav({
  activeClass = "bg-gradient-to-r from-[#FA5252] to-[#DD2476] dark:text-[#A6A6A6] dark:text-white text-white",
  className = "mb-1 rounded-md  cursor-pointer font-poppins bg-white text-gray-lite font-medium mx-2.5 flex text-xtiny py-2.5 px-2 md:px-4 xl:px-5 items-center transition-all duration-300 ease-in-out dark:hover:text-white dark:bg-[#212425] hover:text-white hover:bg-gradient-to-r from-[#FA5252] to-[#DD2476] dark:text-[#A6A6A6] ",
  icon = null,
  to = "",
  itemName = "",
  openNav,
}) {
  return (
    <NavLink
      className={({ isActive }) =>
        `${className} ${isActive ? activeClass : ""}`
      }
      to={to}
      onClick={() => {openNav(false);
      }}
    >
      <span className="text-xl mb-1 mr-4">{icon}</span>
      {itemName}
    </NavLink>
  );
}

export default ItemNav;
