const data = {
  Business: [
    {
      cover: "/images/work/renewable_energy.jpg",
      bgColor: "bg-blue-100",
      title: "Alhadbaa Company",
      description:
        "I proactively identified and pursued sales opportunities, consistently surpassing targets. I provided personalized product knowledge and exceptional customer service, fostering strong client relationships. My achievements contributed to revenue growth and overall client satisfaction.",
    },
    {
      cover: "/images/work/pharmacy.jpg",
      bgColor: "bg-pink-100",
      title: "Life Company",
      description:
        "I proactively identified and pursued sales opportunities, consistently surpassing targets. I provided personalized product knowledge and exceptional customer service, fostering strong client relationships. My achievements contributed to revenue growth and overall client satisfaction.",
    },
  ],
  "Application": [
    {
      cover: "/images/work/chatapp.jpg",
      bgColor: "bg-yellow-100",
      title: "Messenger",
      description:
        "I proactively identified and pursued sales opportunities, consistently surpassing targets. I provided personalized product knowledge and exceptional customer service, fostering strong client relationships. My achievements contributed to revenue growth and overall client satisfaction.",
    },
  ],
};
export default data;
