function Footer() {
  // useEffect();
  return (
    <>
      <footer className="overflow-hidden rounded-b-2xl bg-slate-50 dark:bg-black">
        <div className="container">
          <p className="text-center py-6 text-gray-lite dark:text-color-910 dark:text-[#A6A6A6]">
            Empowering Your Business with Cutting-Edge Development.
          </p>
        </div>
      </footer>
    </>
  );
}
export default Footer;
