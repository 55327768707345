import Header from "../components/header";
import RightSideBar from "../components/rightSideBar";
import Content from "../components/content";

function Layout() {
  return (
    <>
      <Header />
      <main className=" xl:max-w-[1280px] lg:max-w-[992px] grid grid-cols-12 md:gap-10 justify-between lg:mt-[220px] mx-auto">
        <RightSideBar />
        <Content />
      </main>
    </>
  );
}

export default Layout;
